import React, { useEffect, useState } from "react";
import styles from "./callCenter.module.css";
import { observer } from "mobx-react";
import Div100vh from 'react-div-100vh'
import axios from "axios";
import { Redirect } from "react-router-dom";
import { customAxios } from "../helpers/axios";

class CreateCall extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      redirect:false,
      name:"Test User",
      surname:"Test User Surname",
      email:"a@a.com",
      phone:"+90 555 555 5555"
    }
  }

  render() {
    const {
      redirect,
      name,
      surname,
      email,
      phone,
     } = this.state;

     if(redirect){
      return <Redirect  to={redirect}/>
    }

    return (
      <Div100vh className={styles.container} style={{justifyContent:"center",alignItems:"center"}}>
        <span>Create Call</span>
        <input placeholder="Name" value={name} onChange={ (e) => {
          this.setState({name:e.target.value})
        }} className={styles.messageInput} style={{padding:5,width:"50vh",marginTop:20,height:"100px",flex:0}} type="text"/>
        <input placeholder="Surname" value={surname} onChange={ (e) => {
          this.setState({surname:e.target.value})
        }} className={styles.messageInput} style={{padding:5,width:"50vh",marginTop:20,height:"100px",flex:0}} type="text"/>
        <input placeholder="E-Mail" value={email} onChange={ (e) => {
          this.setState({email:e.target.value})
        }} className={styles.messageInput} style={{padding:5,width:"50vh",marginTop:20,height:"100px",flex:0}} type="email"/>
        <input placeholder="Phone" value={phone} onChange={ (e) => {
          this.setState({phone:e.target.value})
        }} className={styles.messageInput} style={{padding:5,width:"50vh",marginTop:20,height:"100px",flex:0}} type="phone"/>

        <div onClick={async () => {
          const customer = {
            email,
            phone,
            surname,
            name
          };
          customAxios.post("/live-store/encrypt",{customer,companyId:"606338fbddcb9b5d42f961ce"}).then((r) => {
            
            const { message } = r.data;
            customAxios.post("/live-store/create",{message,companyId:"606338fbddcb9b5d42f961ce"}).then((_r) => {
              if(_r.data.done){
                window.location.href = _r.data.url;
                this.setState({redirect:_r.data.url});
              }
            });
          });
          
        }} className={styles.button} style={{width:"50vh",borderRadius:8,color:"white",marginTop:8}}>
          Create Call
        </div>

      </Div100vh>
    )
  }
}

export default CreateCall