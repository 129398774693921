import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, useParams, Switch, Redirect } from 'react-router-dom'
import FOF from './404';
import CallScreen from './pages/CallScreen';
import StaticStore from "./stores/staticStore";
import CreateCall from "./pages/createCall";


const App = () => {
  const { innerWidth: width, innerHeight: height } = window;
  StaticStore.setHeight(height);
  StaticStore.setWidth(width);
  return(
    <Router>
      <Switch>
        <Route path="/create-call" component={CreateCall} />
        <Route path="/live-store/:callId" component={CallScreen} />
        {/* <Redirect from="*" to="/404" /> */}
      </Switch>
    </Router>
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
